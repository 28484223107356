import { Typography } from "@mui/material";
import React from "react";
import playStoreBlack from "../public/assets/images/home/playStoreBlack.svg";
import appStoreBlack from "../public/assets/images/home/appStoreBlack.svg";
import AndroidPhone from "../public/assets/images/home/AndroidPhone.webp";
import Iphone13promax from "../public/assets/images/home/Iphone13promax.webp";
import { Grid } from "@mui/material";
import { appLink } from "../utils/Constants";
import Image from "next/image";
import Link from "next/link";

function ApplicationCTA() {
  return (
    <Grid
      sx={{
        minHeight: { xs: "auto", md: "60vh" },
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: "50px 0", md: "120px 0" },
      }}
    >
      <Grid
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "90%",
          width: "85%",
          background:
            "linear-gradient(60.24deg, #08AC17 -21.65%, #4D71D0 93.65%)",
          color: "white",
          borderRadius: "15px",
          maxWidth: "1440px",
          position: "relative",
        }}
      >
        <Grid
          sx={{
            width: { xs: "100%", md: "50%" },
            padding: { xs: "5% 8%", md: "3% 8%" },
          }}
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <Typography
            fontWeight="bold"
            sx={{ fontSize: { xs: "25px", md: "36px" } }}
          >
            Sell More To Earn More! with Zero Investment
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              margin: { xs: "5% 0" },
            }}
          >
            Download the app today.
          </Typography>

          <Grid
            container
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              sx={{
                height: { xs: "45px", md: "60px" },
                width: "auto",
                margin: "5px",
                display: { xs: "none", md: "block" },
                cursor: "pointer",
              }}
            >
              <Link href={appLink}>
                <Image
                  src={playStoreBlack}
                  alt="playStore"
                  width={200}
                  height={200}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{ display: { xs: "none", md: "block" } }}
          style={{
            width: "50%",
            position: "absolute",
            top: "-5%",
            bottom: "-5%",
            right: 0,
          }}
        >
          <Image
            src={AndroidPhone}
            loading="lazy"
            placeholder="blur"
            alt="androidPhone"
            width={500}
            height={800}
            style={{
              position: "absolute",
              left: "26%",
              zIndex: "1",
              objectFit: "contain",
            }}
          />
          <Image
            src={Iphone13promax}
            loading="lazy"
            width={400}
            height={700}
            alt="Iphone13promax"
            style={{
              position: "absolute",
              left: "44%",
              objectFit: "contain",
              marginTop: "2%",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ApplicationCTA;
